const Arrow = () => {
  return (
    <svg height='10' width='11'>
      <path
        fill='#0445af'
        d='M7.586 5L4.293 1.707 5.707.293 10.414 5 5.707 9.707 4.293 8.293z'
      ></path>
      <path fill='#0445af' d='M8 4v2H0V4z'></path>
    </svg>
  );
};

export default Arrow;
